import { Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, Observable } from 'rxjs';

export interface Parameters {
    page: number,
    hits: string,
    type: string,
    query: string,
    sort: string,
    year: string,
    language: string,
}


type AllowedKeys = keyof Parameters;

type QueryParamSubset<T extends AllowedKeys[]> = {
    [K in T[number]]: Parameters[K];
};

type ParameterAccumulator<T extends AllowedKeys[]> = {
    [K in keyof QueryParamSubset<T>]: QueryParamSubset<T>[K];
};

@Directive()
// deprecate with signal inputs
export abstract class PagedComponent implements OnDestroy {
    protected abstract pageSize: number;
    private readonly fallbackParameters: Parameters;

    protected constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        private defaults?: Partial<Parameters>
    ) {
        this.fallbackParameters = {
            hits: 'descending',
            type: '',
            query: '',
            sort: 'published',
            year: '',
            language: '',
            page: 1,
            ...defaults
        };
    }

    protected observeParameters<T extends AllowedKeys[]>(...keys: T): Observable<QueryParamSubset<T>> {
        return this.route.queryParamMap.pipe(
            map(params => {
                let observedParameters: ParameterAccumulator<T> = {} as ParameterAccumulator<T>;

                for (const key of keys) {
                    const param = params.get(key);

                    if (!param && this.defaults?.[key]) {
                        this.setDefaultParameter(key, this.fallbackParameters[key]);
                    }

                    // @ts-ignore
                    observedParameters[key] = params.get(key) ?? this.fallbackParameters[key];
                }

                return observedParameters;
            }),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        );
    }

    protected offset(page: number): number {
        return this.pageSize * (page - 1);
    }

    private setDefaultParameter(key: string, value: string | number): void {
        this.router.navigate([], {
            queryParams: { [key]: value },
            queryParamsHandling: 'merge',
        })
    }

    ngOnDestroy(): void {
        this.router.navigate([], {
            queryParams: { page: null },
            queryParamsHandling: 'merge',
            skipLocationChange: true
        })
    }

}
